/*
// .topbar-link
*/
@import '../variables';


.topbar-link {

    color: $topbar-link-color;
    transition: color .3s;
     @include direction {
        #{$padding-inline-end}: #{11px - 1px};
    }
    &:hover {
        color: $topbar-link-hover-color;
    }
}
