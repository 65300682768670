/*
// .block
*/
@import '../variables';
@import '../functions';


.block {
    margin-bottom: $block-margin-bottom;
}
.block--highlighted {
    padding: 50px 0 60px;
    background: $block-highlighted-bg;
}

.form-txt-mt {
	margin-top:.5rem;
}

.d--btn-block {
display:flex;
	.d-flex-btn {
		

	}
	.d-flex-span {
		margin: auto;
    flex-grow: 1;
    margin-left: 20px;
	}
}

@media (max-width: breakpoint(md-end)) {
    .block {
        margin-bottom: $block-margin-bottom-md;
    }
}