.react-go-modal {
    background: #fff;
    padding: 20px;
    width: 50%;
    min-width: 360px;
    z-index: 10000;
    box-shadow: -12px 12px 18px 10px #75707038;
}

.react-go-modal-Overlay {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.ReactModalPortal {
    position: absolute;
    top: 25%;
    display: flex;
    width: 100%;
}