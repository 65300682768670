.react-autosuggest__container {
  position: relative;
    border: none;
    width: 100%;
}

.react-autosuggest__input {
    width: 100%;
    height: 45px;
    padding: 10px 45px 10px 15px;
    font-weight: 300;
    font-size: 14px;
    border: none;
    color: #000 !important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 54px;
    width: 111%;
    border: 1px solid #e2e2e2;
    background-color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 14px;
    z-index: 9999;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
      cursor: pointer;
    padding: 10px 15px;
    display: block;
    font-size: 14px !important;
    a {
      color:#444;
    }
}

.react-autosuggest__suggestion--highlighted {
  background-color: #fff;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

.react-autosuggest__suggestion-match {
  color: #212121;
  font-weight: bold;
}