/*
// .topbar
*/
@import '../variables';
@import '../mixins/direction';


$local-item-margin: 0;


.topbar {
    height: $topbar-height;
    background: $topbar-bg;
    box-shadow: $topbar-shadow;
    border-bottom: $topbar-border-bottom;
    font-size: 14px;
    line-height: 14px;
    color: $topbar-font-color;
    position: relative;
    z-index: 20;
    .topbar__item~.topbar__item~.topbar__item {
        background: #ffd401;
        border-bottom-right-radius:10px;
        padding:0px;
        &:hover {
            color: $topbar-link-hover-color;
        }
    }
    .topbar__item{
        border-bottom-left-radius:10px;
        .topbar-link {
        &:hover {
            color: $topbar-link-hover-color-white;
        }
        }
    } 
    .topbar__item~.topbar__item {
        border-radius:0;
        .topbar-link {
        &:hover {
            color: $topbar-link-hover-color-white;
        }
        }
    }
}
.topbar__container {
    height: 100%;
}
.topbar__row {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 #{-$local-item-margin};
}
.topbar__item {
    margin: 0 $local-item-margin;
    height: 100%;
    display: flex;
    align-items: center;
    background:rgb(61, 70, 77);
    padding:0 16px;

}

.topbar__item-left {
    margin: 0 $local-item-margin;
    height: 100%;
    display: flex;
    align-items: center;
}
.topbar__item-value {
    color: $topbar-dropdown-value-color;
    transition: all .1s;
}
.topbar__spring {
    flex-grow: 1;
}
.topbar__item--link-left {
    color:#737373;
}
.topbar__item--link {
    color:#fff;
}
.topbar__item--link + .topbar__item--link {
    border-left:1px solid #a29797;
    @include direction {
        #{$margin-inline-start}: 0px;
    }
}
