/*
// .site
*/
@import '../variables';


.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
}
.site__body {
    flex-grow: 1;
}
.site__footer {
    flex-shrink: 0;
}

.fab-container-c {
	z-index: 1000;
	top:unset;
	bottom: 80px;
    right: 1.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	button {
		background-color: rgb(204, 50, 50) !important;
		font-size:38px;
	&:hover,&:active,&:focus {
		transform: rotate(0deg) !important;
	}
	a {
		width: inherit;
    height: inherit;
    position: absolute;
	}
	}
}

@media (min-width: 992px)  {
	.fab-container-c {
    display: none !important;
}
}
