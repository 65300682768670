/*
// .search
*/
@import '../variables';


.search {}
.search__form {
    position: relative;
    height: $search-height;
    display: flex;
}
.search__border {
    z-index: -1;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: $search-border-radius;
    transition: box-shadow .15s, background .15s;
}
.search__input {
    border: none;
    background: transparent;
    width: 1px;
    flex-grow: 1;
    font-size: 15px;
    transition: color .15s;
    color: $search-input-default-font-color;

    &::placeholder {
        color: $search-input-default-placeholder;
    }
    & ~ .search__border {
        background: $search-input-default-bg;
        box-shadow: $search-input-default-shadow;
    }

    &:hover {
        color: $search-input-hover-font-color;

        &::placeholder {
            color: $search-input-hover-placeholder;
        }
        & ~ .search__border {
            background: $search-input-hover-bg;
            box-shadow: $search-input-hover-shadow;
        }
        & ~ .search__button {
            fill: $search-input-hover-icon-color;

            &:hover {
                fill: $search-input-hover-icon-hover-color;
            }
        }
    }
    &:focus {
        outline: none;
        color: $search-input-focus-font-color;

        &::placeholder {
            color: $search-input-focus-placeholder;
        }
        & ~ .search__border {
            background: $search-input-focus-bg;
            box-shadow: $search-input-focus-shadow;
        }
        & ~ .search__button {
            fill: $search-input-focus-icon-color;

            &:hover {
                fill: $search-input-focus-icon-hover-color;
            }
        }
    }

    .react-autosuggest__container {
        width:100%;
        height: 100%;
        input {
            width: 100%;
            height: 90%;
            border: 0px solid #fff;
            margin: 0.2rem 0%;

               &:focus {
                outline: none;
                color: $search-input-focus-font-color;

                &::placeholder {
                    color: $search-input-focus-placeholder;
                }
               
                
            }
        }
    }
}
.search__button {
    border: none;
    padding: 0;
    background: transparent;
    flex-grow: 0;
    width: $search-height;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: $search-input-default-icon-color;
    transition: fill .15s;

    &:hover,
    &:focus {
        outline: none;
        fill: $search-input-default-icon-hover-color;
    }
}

.first-wrap {
    width: 38px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    .ctg-react{
        &:focus {
           outline: unset;
        }
        .ctg-react__control {
            border:none !important;
            box-shadow:none !important;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        .ctg-react__indicator {
            color:#fff;
        }
    }
    .choices__inner {
        background: transparent;
        border-radius: 0;
        border: 0;
        height: 100%;
        color: #fff;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 10px 30px;
        .choices__list.choices__list--single {
            display: -ms-flexbox;
            display: flex;
            padding: 0;
            -ms-flex-align: center;
            align-items: center;
            height: 100%;
        }
        .choices__item.choices__item--selectable.choices__placeholder {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            height: 100%;
            opacity: 1;
            color: #888;
        }
        .choices__list--single {
            .choices__item {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                height: 100%;
                color: #555;
            }
        }
    }
    .choices[data-type*="select-one"] {
        &:after {
            right: 30px;
            border-color: #e5e5e5 transparent transparent transparent;
        }
    }
    .choices__list.choices__list--dropdown {
        border: 0;
        background: #fff;
        padding: 20px 30px;
        margin-top: 2px;
        border-radius: 4px;
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
        .choices__item--selectable {
            padding-right: 0;
        }
    }
    .choices__list--dropdown {
        .choices__item--selectable.is-highlighted {
            background: #fff;
            color: #63c76a;
        }
        .choices__item {
            color: #555;
            min-height: 24px;
        }
    }
}
