/*
// .lot-sales
*/
@import '../variables';
@import '../functions';

.lot-sales {

}

.lot-sales-card {
position: relative;
	display: flex;
	flex-direction: row;
    min-height: auto;
    &:before {
    	content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #ededed inset;
    transition: box-shadow .2s;
    }
}

.lot-sales__columns {
	padding: 16px 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    .product-card__name {
    	font-size: 16px;
	    line-height: 20px;
	    flex-grow: 0;
	    font-weight: 500;
    }
}

.lot-sales__id {
    padding: 2px 22px 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    .product-card__name {
    	font-size: 16px;
	    line-height: 20px;
	    flex-grow: 0;
	    font-weight: 500;
    }
}
.lot-sales__desc {
    padding: 22px 22px 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
      /* min-width: 50%;
    max-width: 100%; */
    width: 35%;
        p{
            color:#444;
            margin: 0;
    padding: 0;
        }
}

.lot-sales-txt-area{
    white-space: normal;
    word-break: break-word;
}

@media (max-width:767px) {
    .lot-sales-card {
        display: none;
    }
    .product-card {
    .lot-sales__desc {
        min-width:100%;
    }

.lot-sales__pdf {
    min-width:100%;
    padding: 0px 20px;
    .product-card__prices {
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
    }
}

.lot-sales__static_image {
    min-width:100%;
}

 .lot-sales__actions {
    min-width: 100%;
    flex-direction: revert;
    justify-content: space-evenly;
    padding: 10px 20px;
    }
}
    
}
.lot-sales__pdf {
    padding: 22px 22px 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        /* min-width: 18%; */
        width: 20%;
    .product-card__prices {
        display: flex;
    flex-direction: column;
    line-height: 28px;
    justify-content: center;
    align-items: center;
    .product-card__new-price {
        font-size: 19px;
        color: #00B050;
    }
    .product-card__old-price {
        font-size: 16px !important;
        color: #FF0000;
    }
    .product-card__old-discount {
        font-size: 16px !important;
        color: #444;
        padding: 3px 6px;
        border: 1px dashed #FF0000;
        border-radius: 5px;
    }
    }    
}

.lot-sales__static_image {
padding: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        /* min-width: 16%; */
        width: 20%;
        img {
            display: block;
            height: 120px;
    }
}

.lot-sales__broderleft {
flex-shrink: 0;
	 @include direction {
            #{$border-inline-start}: 1px solid $product-card-divider-color;
        }
}

 .lot-sales__actions {
        flex-shrink: 0;
            /* min-width: 16%; */
            width: 25%;
        padding: 22px 20px;
flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
        @include direction {
            #{$border-inline-start}: 1px solid $product-card-divider-color;
        }
    }

.lot-sales__default__image {
    padding: 22px;
    flex-shrink: 0;
    /* min-width: 16%; */
    width: 20%;
    a {
        display: block;
        position: relative;
    }
    .card-image__default {
        display: block;
        position: relative;
    }
}
